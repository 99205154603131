// components/ConfirmModal.jsx
import React from "react";

function ConfirmModal({ isOpen, onClose, onConfirm, message }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <p className="mb-4">{message}</p>
        <button
          onClick={onConfirm}
          className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Yes
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
        >
          No
        </button>
      </div>
    </div>
  );
}

export default ConfirmModal;
