import React, { useState, useEffect } from "react";

const Drawer = ({
  onClose,
  onSave,
  onSaveAndEvaluate,
  response,
  setResponse,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // Trigger the animation when the component mounts
  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex ${
        !isOpen && "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`ml-auto transform transition-transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } w-1/2 h-full bg-white p-4 overflow-y-auto`}
      >
        <h2 className="text-lg font-bold mb-4">Add New Evaluation</h2>
        <textarea
          placeholder="Prompt"
          value={response}
          onChange={(e) => setResponse(e.target.value)}
          className="w-full h-[85%] p-2 border rounded mb-4 resize-y"
        />
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => onSave(response)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Save
          </button>
          <button
            onClick={() => onSaveAndEvaluate(response)}
            className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Save and Evaluate
          </button>
          <button
            onClick={() => {
              onClose();
              setIsOpen(false);
            }}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
