import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaCheck, FaTimes, FaSave } from "react-icons/fa";
import useGetTypes from "./Endpoints/GetTypes";
import useCreateType from "./Endpoints/CreateType";
import useUpdateSession from "./Endpoints/UpdateSession";
import useCreateResponse from "./Endpoints/CreateResponse";
import useGenerateResponse from "./Endpoints/GenerateResponse";
const ViewResponse = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getTypes = useGetTypes();
  const createType = useCreateType();
  const updateSession = useUpdateSession();
  const createResponse = useCreateResponse();
  const generateResponse = useGenerateResponse();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [name, setName] = useState(""); // Separate state for name

  const initialResponse = {
    response: "",
    type_id: "",
    updated: false,
    editMode: false,
  };
  const [responses, setResponses] = useState([initialResponse]);
  const [types, setTypes] = useState([]); // Initial types
  const [activeTab, setActiveTab] = useState("addResponse");
  const [aiPrompt, setAiPrompt] = useState("");
  const handleAddResponse = (e) => {
    e.preventDefault();
    setResponses([
      ...responses,
      { response: "", type_id: "", updated: false, editMode: true },
    ]);
  };

  const handleResponseChange = (index, field, value) => {
    const newResponses = [...responses];
    newResponses[index][field] = value;
    newResponses[index].updated = false;
    newResponses[index].editMode = true;
    setResponses(newResponses);
  };

  const handleResponseSave = (index) => {
    const newResponses = [...responses];
    newResponses[index].updated = true;
    newResponses[index].editMode = false;
    setResponses(newResponses);
  };

  const handleDelete = (index) => {
    const newResponses = responses.filter((_, idx) => idx !== index);
    setResponses(newResponses);
  };

  const handleSaveClose = (e) => {
    e.preventDefault();
    // Check if all fields are filled
    const isFormValid =
      name.trim() !== "" &&
      responses.every(
        (response) => response.response.trim() !== "" && response.type_id !== ""
      );

    if (!isFormValid) {
      alert("Please fill out all fields before saving.");
      return;
    }

    updateSession(location?.state?.session_id, name);
    createResponse(location?.state?.session_id, responses, "close");
    //navigate("/");
  };

  const handleSaveNext = (e) => {
    e.preventDefault();
    // Check if all fields are filled
    const isFormValid =
      name.trim() !== "" &&
      responses.every(
        (response) => response.response.trim() !== "" && response.type_id !== ""
      );

    if (!isFormValid) {
      alert("Please fill out all fields before saving.");
      return;
    }

    updateSession(location?.state?.session_id, name);
    createResponse(location?.state?.session_id, responses, "next");
    //navigate("/");
  };

  const handleTypeChange = (index, value) => {
    if (value === "add_new") {
      const newType = prompt("Enter new type:");
      if (newType && !types.includes(newType)) {
        createType(newType);
        getTypes(setTypes);
        handleResponseChange(index, "type_id", newType);
      }
    } else {
      handleResponseChange(index, "type_id", value);
    }
  };
  useEffect(() => {
    getTypes(setTypes);
  }, []);
  const handleTabSwitch = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
    if (tab === "addResponse") {
      setResponses([initialResponse]); // Reset to initial state when switching to "Add Response"
    } else if (tab === "aiResponse") {
      setResponses([]); // Clear responses when switching to "AI Response"
    }
  };

  const renderTabContent = () => {
    if (activeTab === "aiResponse") {
      return (
        <>
          <div>
            <textarea
              placeholder="Enter AI Prompt"
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
              rows="6"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 "
            />
            <button
              onClick={handleGenerateAiResponse}
              disabled={isSubmitted} // Disable button when isSubmitted is true
              className={`px-4 py-2 bg-blue-700 text-white rounded-lg float-right flex items-center ${
                isSubmitted ? "opacity-50 cursor-not-allowed " : ""
              }`}
            >
              Generate
              {isSubmitted && (
                <div role="status" className="ml-2">
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              )}
            </button>
          </div>
          {renderResponseFields()}
        </>
      );
    } else {
      return renderResponseFields();
    }
  };
  const handleGenerateAiResponse = (e) => {
    e.preventDefault();
    generateResponse(setResponses, aiPrompt, setIsSubmitted);
  };

  const renderResponseFields = () => (
    <>
      {responses.map((response, index) => (
        <div key={index} className="relative">
          <div className="flex w-full">
            <select
              value={response.type_id}
              onChange={(e) => handleTypeChange(index, e.target.value)}
              className=" px-3 py-2 h-[40px] border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 mb-2 mt-2"
              style={{ width: activeTab === "aiResponse" ? "70%" : "100%" }}
            >
              <option value="">Select Type</option>
              {types.map((type, index2) => (
                <option key={index2} value={type.type_id}>
                  {type.type}
                </option>
              ))}
              <option value="add_new">Add New Type...</option>
            </select>
            <input
              type="text"
              placeholder="Category *"
              required
              value={response.category}
              style={{
                display: activeTab === "aiResponse" ? "" : "none",
              }}
              className="w-[35%] h-[40px] mt-[8px] ml-[8px] px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 mb-4"
            />
          </div>
          <textarea
            placeholder="Response"
            value={response.response}
            onChange={(e) =>
              handleResponseChange(index, "response", e.target.value)
            }
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
            rows="6"
            onFocus={() => {
              const newResponses = [...responses];
              newResponses[index].editMode = true;
              setResponses(newResponses);
            }}
          />
          {response.updated && !response.editMode && (
            <div className="absolute right-3 top-[56px] text-green-500">
              <FaCheck />
            </div>
          )}
          {response.editMode && (
            <div className="absolute right-3 bottom-3 flex space-x-2">
              <button
                onClick={() => handleResponseSave(index)}
                className="text-blue-500"
              >
                <FaSave />
              </button>
              <button
                onClick={() => handleDelete(index)}
                className="text-red-500"
              >
                <FaTimes />
              </button>
            </div>
          )}
        </div>
      ))}
      <div className="flex justify-center">
        <button
          onClick={handleAddResponse}
          className="flex items-center justify-center w-10 h-10 bg-green-500 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-green-300 mt-[24px]"
          aria-label="Add New Field"
        >
          <FaPlus />
        </button>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div
        className="bg-gray-100  flex flex-col items-center p-4"
        style={{ minHeight: "100vh" }}
      >
        <input
          type="text"
          placeholder="Name *"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full max-w-4xl px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 mb-4"
        />

        <form className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-md space-y-2">
          <div className="flex space-x-4 w-full border-b">
            <button
              onClick={(e) => handleTabSwitch(e, "addResponse")}
              className={`px-4 py-2 font-medium text-lg ${
                activeTab === "addResponse"
                  ? "border-b-2 border-blue-700 text-blue-700"
                  : "text-gray-500 hover:text-gray-700"
              } focus:outline-none`}
            >
              Add Response
            </button>
            <button
              onClick={(e) => handleTabSwitch(e, "aiResponse")}
              className={`px-4 py-2 font-medium text-lg ${
                activeTab === "aiResponse"
                  ? "border-b-2 border-blue-700 text-blue-700"
                  : "text-gray-500 hover:text-gray-700"
              } focus:outline-none`}
            >
              AI Response
            </button>
          </div>
          {renderTabContent()}
          <div className="flex justify-between mt-4">
            <button
              onClick={handleSaveClose}
              className="px-4 py-2 bg-gray-700 text-white rounded-lg"
            >
              Save and Close
            </button>
            <button
              onClick={handleSaveNext}
              className="px-4 py-2 bg-blue-700 text-white rounded-lg"
            >
              Save and Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ViewResponse;
