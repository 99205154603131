import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_EVALUATE_PROMPT_EVALUATIONS_END_POINT,
} from "../Constants/ApiConstant";

const useEvaluatePrompt = () => {
  const evaluatePrompt = (
    callback,
    sessionId,
    prompt,
    responseList,
    SetisSubmitting
  ) => {
    SetisSubmitting(true);
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(
        REACT_APP_REST_API_BASE_URL +
          REACT_APP_EVALUATE_PROMPT_EVALUATIONS_END_POINT,
        {
          session_id: sessionId,
          prompt: prompt,
          response_list: responseList,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        callback(response.data);
        SetisSubmitting(false);

        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return evaluatePrompt;
};

export default useEvaluatePrompt;
