import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_GET_EVALUATIONS_END_POINT } from "../../Components/Constants/ApiConstant";

const useGetEvaluations = () => {
  const getEvaluations = (callback, sessionId, setResponseList, setEvaluationList) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_GET_EVALUATIONS_END_POINT,
        {
          session_id: sessionId,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log("response.data", response.data);
        callback(response.data);
        setResponseList(response.data.response_list);
        setEvaluationList(response.data.eval_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return getEvaluations;
};

export default useGetEvaluations;
