import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import ViewResponse from "./Components/ViewResponse";

import "./App.css";
import ViewEvaluation from "./Components/ViewEvaluation";
import ViewPreviousEvaluation from "./Components/ViewPreviousEvaluation";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/view_response" element={<ViewResponse />} />
        <Route path="/view_evaluation" element={<ViewEvaluation />} />
        <Route path="/view_previous_evaluation" element={<ViewPreviousEvaluation />} />
      </Routes>
    </Router>
  );
}

export default App;
