import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_CREATE_RESPONSE_END_POINT } from "../../Components/Constants/ApiConstant";
import { useNavigate } from "react-router-dom";
const useCreateResponse = () => {
  const navigate = useNavigate();
  const createResponse = (sessionId, sessionText, close) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_CREATE_RESPONSE_END_POINT,
        {
          session_id: sessionId,
          response_list: sessionText,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (close === "close") {
          navigate("/");
        } else {
          navigate("/view_evaluation", {
            state: {
              sessionId: sessionId,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return createResponse;
};

export default useCreateResponse;
