import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_GET_SESSIONS_END_POINT } from "../../Components/Constants/ApiConstant";
const useGetSessions = () => {
  const getSessions = (callback) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .get(REACT_APP_REST_API_BASE_URL + REACT_APP_GET_SESSIONS_END_POINT, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: access_token,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("data sessions", response.data.session_list);
        callback(response.data.session_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return getSessions;
};

export default useGetSessions;
