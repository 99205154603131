import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_GET_TYPES_END_POINT,
} from "../../Components/Constants/ApiConstant";
import { useNavigate } from "react-router-dom";
const useGetTypes = () => {
  const navigate = useNavigate();
  const getTypes = (callback) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .get(REACT_APP_REST_API_BASE_URL + REACT_APP_GET_TYPES_END_POINT, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: access_token,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("data here", response.data.response_type_list);
        callback(response.data.response_type_list);
        // navigate("/view_response", {
        //   state: { session_id: response.data.session_id },
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return getTypes;
};

export default useGetTypes;
