import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlay, FaHistory } from "react-icons/fa";
import Header from "./Header";
import useCreateSession from "./Endpoints/CreateSession";

const Home = () => {
  const [sessiodId, setSessionId] = useState();
  const createSession = useCreateSession();
  const navigate = useNavigate();
  const handleNewEvaluation = () => {
    // Logic for starting a new evaluation
    createSession(setSessionId);
    // navigate("/view_response");
  };

  const handlePreviousEvaluation = () => {
    // Logic for previous evaluation
    console.log("Loading previous evaluation...");
    navigate("/view_previous_evaluation");
  };

  return (
    <>
      {" "}
      <Header />
      <div className="App  flex flex-col items-center justify-center h-[92.4vh] bg-[#2a3b5d]">
        <div className="flex flex-row space-x-20  ">
          <div
            className="bg-gradient-to-br from-red-500 to-red-900 hover:from-red-600 hover:to-red-800 text-white font-bold rounded-xl focus:outline-none focus:ring-4 focus:ring-red-300 shadow-2xl cursor-pointer transition duration-300 ease-in-out"
            onClick={handleNewEvaluation}
            style={{
              width: "280px",
              height: "280px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <FaPlay className="text-4xl mb-3" />
            <p className="text-xl ">Start New Evaluation</p>
          </div>
          <div
            className="bg-gradient-to-br from-blue-500 to-blue-900 hover:from-blue-600 hover:to-blue-800 text-white font-bold rounded-xl focus:outline-none focus:ring-4 focus:ring-blue-300 shadow-2xl cursor-pointer transition duration-300 ease-in-out"
            onClick={handlePreviousEvaluation}
            style={{
              width: "280px",
              height: "280px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <FaHistory className="text-4xl mb-3" />
            <p className="text-xl">Previous Evaluation</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
