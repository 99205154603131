import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_CREATE_SESSION_END_POINT,
} from "../../Components/Constants/ApiConstant";
import { useNavigate } from "react-router-dom";
const useCreateSession = () => {
  const navigate = useNavigate();
  const createSession = (callback) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_CREATE_SESSION_END_POINT,
        {
          session_name: "new session",
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        callback(response.data);
        navigate("/view_response", {
          state: { session_id: response.data.session_id },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return createSession;
};

export default useCreateSession;
