import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_GENERATE_RESPONSE_END_POINT,
} from "../Constants/ApiConstant";

const useGenerateResponse = () => {
  const generateResponse = (
    callback,

    prompt,
    SetisSubmitted
  ) => {
    SetisSubmitted(true);
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_GENERATE_RESPONSE_END_POINT,
        {
          prompt: prompt,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        const newResponses = response.data.response_list.student_attempts.map(
          (item) => ({
            response: item.attempt,
            type_id: "", // Assuming you need to set this or map it from the response
            updated: false,
            editMode: false,
            category: item.category,
          })
        );
        callback(newResponses); // Update your state with the new responses
        SetisSubmitted(false);
      })

      .catch((error) => {
        SetisSubmitted(false);
        alert(error);
        console.log(error);
      });
  };
  return generateResponse;
};

export default useGenerateResponse;
