import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_CREATE_PROMPT_EVALUATIONS_END_POINT,
} from "../../Components/Constants/ApiConstant";
const useCreatePrompt = () => {
  const createPrompt = (sessionId, promptText, SetisSubmitting) => {
    SetisSubmitting(true);
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        REACT_APP_REST_API_BASE_URL +
          REACT_APP_CREATE_PROMPT_EVALUATIONS_END_POINT,
        {
          session_id: sessionId,
          prompt: promptText,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        SetisSubmitting(false);

        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return createPrompt;
};

export default useCreatePrompt;
