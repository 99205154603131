export const REACT_APP_REST_API_BASE_URL =
  process.env.REACT_APP_REST_API_BASE_URL;
export const REACT_APP_CREATE_SESSION_END_POINT =
  process.env.REACT_APP_CREATE_SESSION_END_POINT;
export const REACT_APP_GET_TYPES_END_POINT =
  process.env.REACT_APP_GET_TYPES_END_POINT;
export const REACT_APP_CREATE_TYPE_END_POINT =
  process.env.REACT_APP_CREATE_TYPE_END_POINT;
export const REACT_APP_UPDATE_SESSION_END_POINT =
  process.env.REACT_APP_UPDATE_SESSION_END_POINT;
export const REACT_APP_CREATE_RESPONSE_END_POINT =
  process.env.REACT_APP_CREATE_RESPONSE_END_POINT;
export const REACT_APP_GET_EVALUATIONS_END_POINT =
  process.env.REACT_APP_GET_EVALUATIONS_END_POINT;
export const REACT_APP_EVALUATE_PROMPT_EVALUATIONS_END_POINT =
  process.env.REACT_APP_EVALUATE_PROMPT_EVALUATIONS_END_POINT;
export const REACT_APP_CREATE_PROMPT_EVALUATIONS_END_POINT =
  process.env.REACT_APP_CREATE_PROMPT_EVALUATIONS_END_POINT;
export const REACT_APP_GET_SESSIONS_END_POINT =
  process.env.REACT_APP_GET_SESSIONS_END_POINT;
export const REACT_APP_DELETE_SESSION_END_POINT =
  process.env.REACT_APP_DELETE_SESSION_END_POINT;
export const REACT_APP_GENERATE_RESPONSE_END_POINT =
  process.env.REACT_APP_GENERATE_RESPONSE_END_POINT;
